<template lang="html" src="./supplierDataTableParts.template.vue"></template>

<style lang="scss" src="./supplierDataTableParts.scss"></style>

<script>
const i18nData = require('./supplierDataTableParts.i18n');

import { CustomFilterForSearchDatatable, SpinnerBeelse } from '@cloudmanufacturingtechnologies/portal-components';

export default {
  name: 'SupplierDataTableParts',
  props: {
    /**
     * Parts
     */
    items: {
      type: Array,
      default: null
    },
    /**
     * Destination page when clicking on a row
     */
    to: {
      type: String,
      required: true
    },
    search: {
      type: String,
      default: ''
    },
    displayHomologationStatus: {
      type: Boolean,
      default: false
    },
    statusFilter: {
      type: Array,
      default: () => { return []; }
    },
    displayTypeOfProduction: {
      type: Boolean,
      default: false
    },
    typeOfProductionFilter: {
      type: Array,
      default: () => { return []; }
    },
    /**
     * Whether the image preview should be enabled or not
     */
    enableImagePreview: {
      type: Boolean,
      default: true
    },
    /**
     * Parts image, keys are parts UUID, values are DataURL
     */
    partsImageDataURL: {
      type: Object,
      default: () => {return {};}
    }
  },
  components: {
    SpinnerBeelse
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  watch: {
    items: {
      handler: function() {
        this.initImageTooltipValues();
      }
    }
  },
  data() {
    return {
      headers: [],
      imageTooltipValues: {},
    };
  },
  created() {
    this.initImageTooltipValues();
    this.headers = [
      { 
        text: this.$t('Status'),
        value: 'homologation.status', 
        filter: (value, search, item) => {
          if (!this.statusFilter || this.statusFilter?.length === 0 ) return true;
          if(item.status === 'HOMOLOGATED') {
            if(this.statusFilter.includes('HOMOLOGATED')) return true;
          }else{
            if(item.homologation.status === 'NEW' && this.statusFilter.includes('NEW')) return true;
            if(item.homologation.status === 'SUPPLIER_QUOTATION' && this.statusFilter.includes('SUPPLIER_QUOTATION')) return true;
            if(item.homologation.status === 'REJECTED' && this.statusFilter.includes('REJECTED')) return true;
            if(['VALIDATION', 'READY'].includes(item.homologation.status) && this.statusFilter.includes('READY')) return true;
            if(['ORDERED','TESTING'].includes(item.homologation.status) && this.statusFilter.includes('ORDERED')) return true;
          }
        },
        width: '100px',
        show: this.displayHomologationStatus
      },
      { 
        text: this.$t('Type'),
        value: 'dna.displayTypeOfProduction',
        filter: (value, search, item) => {
          if (
            this.typeOfProductionFilter &&
            this.typeOfProductionFilter.length === 0
          ) {
            return true;
          }
          if (
            this.typeOfProductionFilter &&
            this.typeOfProductionFilter.includes(item?.dna?.typeOfProduction)
          ) {
            return true;
          }
        },
        width: '100px',
        show: this.displayTypeOfProduction,
      },
      { 
        text: this.$t('Name'),
        value: 'name',
        show: true
      },
      { 
        text: this.$t('Date'),
        value: 'homologation.lastStatusUpdate',
        show: true,
      },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        show: true
      },
    ];
  },
  computed: {
    computedHeaders() {
      return this.headers.filter(h => {return h.show;});
    }
  },
  methods: {
    sortWarehouseFn(item1, item2) {
      const item1Str = item1.homologation.public ? 'BCM' : `PRIVATE_${this.$appConfig.privateWarehousesObject[item1.brand_id]?.name}`;
      const item2Str = item2.homologation.public ? 'BCM' : `PRIVATE_${this.$appConfig.privateWarehousesObject[item2.brand_id]?.name}`;
      return item1Str > item2Str ? 1 : -1;
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if(!index?.[0]) {
          /**
           * Default key should be homologation.lastStatusUpdate
           */
          index = ['homologation.lastStatusUpdate'];
        }
        if (index[0] === 'homologation.public') {
          if (!isDesc) {
            return this.sortWarehouseFn(a, b);
          } else {
            return this.sortWarehouseFn(b, a);
          }
        } else {
          const aVal = index[0].split('.').reduce((current, k) => {return current[k];}, a);
          const bVal = index[0].split('.').reduce((current, k) => {return current[k];}, b);
          if (!isDesc[0]) {
            return aVal < bVal ? -1 : 1;
          } else {
            return bVal < aVal ? -1 : 1;
          }
        }
      });
      return items;
    },
    customFilter(items, search, filter) {
      return CustomFilterForSearchDatatable.customFilter(items, search, this);
    },
    initImageTooltipValues() {
      /**
       * Init the object for each programmatic tooltip
       */
      if(!this.items) {
        return;
      }
      
      for(const part of this.items) {
        if(!this.imageTooltipValues.hasOwnProperty(part._id)) {
          this.$set(this.imageTooltipValues, part._id, false);
        }
      }
    },
    rowMouseenter(part) {
      if(!this.$vuetify.breakpoint.mobile) {
        setTimeout(() => {
          if(this.$refs[`row_${part._id}`]?.matches(':hover')) {
            this.imageTooltipValues[part._id] = true;
          }
        }, 75);
      }
    },
    rowMouseleave(part) {
      this.imageTooltipValues[part._id] = false;
    },
    rowMouseover(part) {
      if(!this.partsImageDataURL[part._id]) {
        setTimeout(() => {
          if(this.$refs[`row_${part._id}`]?.matches(':hover') && !this.$vuetify.breakpoint.mobile) {
            this.$emit('getPartImage', part);
          }
        }, 200);
      }
    },
    goToPageItem(item) {
      this.$router.push({name: this.to, params: { supplierUUID: `${this.$route.params.supplierUUID}`, partUUID: `${item._id}`}});
    },
  },
};
</script>
