<template>
  <div class="page-customer-parts">
    <v-row justify="space-between" align="center">
      <span class="text-wrap display-1 ma-3">{{ $t('parts') }}</span>
    </v-row>
    <v-divider class="mt-2 mb-4"></v-divider>
    <v-card>
      <v-card-title>
        <DataTableFilter
          class="col-6 pl-0"
          ref="filter"
          @update="updateFilter"
          :filters="filterObject"
        ></DataTableFilter>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          :label="$t('Search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <SupplierDataTableParts
          :items="customerParts"
          :search="search"
          :display-type-of-production="true"
          :display-homologation-status="true"
          :to="'BcmPartOverview'"
          :parts-image-data-u-r-l="partsImageDataURL"
          :type-of-production-filter="typeOfProductionFilter"
          :status-filter="statusFilter"
          @getPartImage="getSupplierPartImage"
        >
        </SupplierDataTableParts>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ApiErrorParser, DataTableFilter, DownloadSupplierPartImage } from '@cloudmanufacturingtechnologies/portal-components';

import SupplierDataTableParts from '../../../components/supplierDataTableParts/SupplierDataTableParts';

const i18nData = require('./pageCustomerParts.i18n.json');

export default {
  name: 'PageCustomerParts',
  components: {
    DataTableFilter, 
    SupplierDataTableParts
  },
  props: {
    /**
     * Customer
     */
    customer: {
      type: Object,
      default: null
    },
    /**
     * Users
     */
    customerUsers: {
      type: Array,
      default: null
    },
    /**
     * Quotes
     */
    customerQuotes: {
      type: Array,
      default: null
    },
    /**
     * Orders
     */
    customerOrders: {
      type: Array,
      default: null
    },
    /**
     * Parts
     */
    customerParts: {
      type: Array,
      default: null
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headers: [],
      search: '',
      typeOfProductionFilter: [],
      statusFilter: [],
      filterObject: {
        typeOfProduction: [
          {
            name: this.$t('Prototype'),
            value: 'prototype',
            img: 'modeling.svg',
            solo: true,
            number: 0,
          },
          {
            name: this.$t('FunctionalPart'),
            value: 'functionalPart',
            img: 'functionalProduction2.svg',
            solo: true,
            number: 0,
          }
        ],
        status: [
          {
            name: this.$t('NEW'),
            value: 'NEW',
            icon: 'fas fa-cogs',
            iconColor: 'primary',
            number: 0,
          },
          {
            name: this.$t('SUPPLIER_QUOTATION'),
            value: 'SUPPLIER_QUOTATION',
            icon: 'fas fa-file-invoice',
            iconColor: 'primary',
            number: 0,
          },
          {
            name: this.$t('REJECTED'),
            value: 'REJECTED',
            icon: 'fas fa-exclamation-circle',
            iconColor: 'error',
            number: 0,
          },
          {
            name: this.$t('readyToOrder'),
            value: 'READY',
            icon: 'fas fa-shopping-cart',
            iconColor: 'orange',
            number: 0,
          },
          {
            name: this.$t('ORDERED'),
            value: 'ORDERED',
            icon: 'fas fa-truck',
            iconColor: 'success',
            number: 0,
          },
          {
            name: this.$t('HOMOLOGATED'),
            value: 'HOMOLOGATED',
            icon: 'fas fa-shield-alt',
            iconColor: 'success',
            number: 0,
          },
        ]
      },
      partsImageDataURL: {}
    };
  },
  mounted() {
    this.reinitializeFilterCount();
  },
  created() {
    this.reinitializeFilterCount();
  },
  updated() {
    this.reinitializeFilterCount();
  },
  methods: {
    reinitializeFilterCount() {
      /**
       * Reset filter number
       */
      this.filterObject.typeOfProduction.forEach(type => {
        type.number = 0;
      });
      this.filterObject.status.forEach(status => {
        status.number = 0;
      });
      this.customerParts.forEach(part => {
        this.$set(this.partsImageDataURL, part._id, null);
        this.filterObject.typeOfProduction.forEach(type => {
          if(type.value === part.dna.typeOfProduction) {
            type.number++;
          }
        });
        /**
         * Update status
         */
        this.filterObject.status.forEach(status => {
          if(part.status === 'HOMOLOGATED') {
            if(status.value === 'HOMOLOGATED') {
              status.number += 1;
            }
          }else{
            if(part.homologation.status === 'VALIDATION' && status.value === 'READY') {
              status.number += 1;
            }else if(part.homologation.status === 'TESTING' && status.value === 'ORDERED') {
              status.number += 1;
            }else if(part.homologation.status === status.value) {
              status.number += 1;
            }
          }
        });
        
      });
    },
    getSupplierPartImage(part) {
      if(part.image) {
        const [promise, req] = DownloadSupplierPartImage.downloadSupplierPartImage(
          this.$apiInstance,
          this.$route.params.supplierUUID, 
          part._id,
          new Date(part.image.created).getTime()
        );

        promise.then(data => {
          const png = new File([new Uint8Array(data.buffer)], 'part.png', {
            type: 'image/png'
          });
          this.partsImageDataURL[part._id] = URL.createObjectURL(png);
        });
      }
    },
    updateFilter(updatedFilters) {
      this.typeOfProductionFilter = updatedFilters.typeOfProduction;
      this.statusFilter = updatedFilters.status;
    },
    goToPageItem(item) {
      this.$router.push({name: 'BcmPartOverview', params: { supplierUUID: `${this.$route.params.supplierUUID}`, partUUID: `${item._id}`}});
    },
  },
};
</script>
