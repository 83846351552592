var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-customer-parts"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('parts')))])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', [_c('v-card-title', [_c('DataTableFilter', {
    ref: "filter",
    staticClass: "col-6 pl-0",
    attrs: {
      "filters": _vm.filterObject
    },
    on: {
      "update": _vm.updateFilter
    }
  }), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('SupplierDataTableParts', {
    attrs: {
      "items": _vm.customerParts,
      "search": _vm.search,
      "display-type-of-production": true,
      "display-homologation-status": true,
      "to": 'BcmPartOverview',
      "parts-image-data-u-r-l": _vm.partsImageDataURL,
      "type-of-production-filter": _vm.typeOfProductionFilter,
      "status-filter": _vm.statusFilter
    },
    on: {
      "getPartImage": _vm.getSupplierPartImage
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }