var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    staticClass: "component-supplierDataTableParts",
    attrs: {
      "headers": _vm.computedHeaders,
      "items": _vm.items || [],
      "search": _vm.search,
      "custom-filter": _vm.customFilter,
      "sort-by": "homologation.lastStatusUpdate",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      },
      "custom-sort": _vm.customSort
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var _item$homologation;

        var item = _ref.item;
        return [_c('tr', {
          ref: "row_".concat(item._id),
          on: {
            "click": function click($event) {
              return _vm.goToPageItem(item);
            },
            "mouseover": function mouseover($event) {
              return _vm.rowMouseover(item);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.rowMouseleave(item);
            },
            "mouseenter": function mouseenter($event) {
              return _vm.rowMouseenter(item);
            }
          }
        }, [_vm.displayHomologationStatus ? _c('td', [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: _vm.to,
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                partUUID: "".concat(item._id)
              }
            },
            "event": ""
          }
        }, [item.status === 'HOMOLOGATED' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v(" fas fa-shield-alt ")]) : [item.homologation.status === 'NEW' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(" fas fa-cogs ")]) : _vm._e(), item.homologation.status === 'SUPPLIER_QUOTATION' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(" fas fa-file-invoice ")]) : _vm._e(), item.homologation.status === 'REJECTED' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "error"
          }
        }, [_vm._v(" fas fa-exclamation-circle ")]) : _vm._e(), item.homologation.status === 'READY' || item.homologation.status === 'VALIDATION' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fas fa-shopping-cart ")]) : _vm._e(), item.homologation.status === 'ORDERED' || item.homologation.status === 'TESTING' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v(" fas fa-truck ")]) : _vm._e()]], 2)], 1) : _vm._e(), _vm.displayTypeOfProduction ? _c('td', [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: _vm.to,
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                partUUID: "".concat(item._id)
              }
            },
            "event": ""
          }
        }, [_c('v-row', {
          staticClass: "pl-2",
          attrs: {
            "align": "center"
          }
        }, [item.dna.typeOfProduction === 'prototype' ? _c('v-img', {
          attrs: {
            "src": require("../../../public/img/icons/modeling.svg"),
            "alt": "",
            "max-width": "30"
          }
        }) : _c('v-img', {
          attrs: {
            "src": require("../../../public/img/icons/functionalProduction2.svg"),
            "alt": "",
            "max-width": "30"
          }
        })], 1)], 1)], 1) : _vm._e(), _c('v-tooltip', {
          ref: "tooltip_image_".concat(item._id),
          attrs: {
            "top": "",
            "content-class": "component-supplierDataTableParts tooltip-image",
            "open-delay": _vm.partsImageDataURL[item._id] ? 35 : 150,
            "color": "transparent",
            "transition": "fade-transition",
            "disabled": !_vm.enableImagePreview
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('td', _vm._g({}, on), [_c('router-link', {
                staticClass: "text-decoration-none d-block text-truncate",
                attrs: {
                  "to": {
                    name: 'Part',
                    params: {
                      supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                      partUUID: "".concat(item._id)
                    }
                  },
                  "event": ""
                }
              }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.imageTooltipValues[item._id],
            callback: function callback($$v) {
              _vm.$set(_vm.imageTooltipValues, item._id, $$v);
            },
            expression: "imageTooltipValues[item._id]"
          }
        }, [_c('v-card', {
          staticClass: "d-flex justify-center align-center",
          attrs: {
            "width": "256px",
            "height": "256px",
            "elevation": "12",
            "shaped": ""
          }
        }, [_c('v-card-text', {
          staticClass: "text-center"
        }, [!item.image ? _c('div', {
          staticClass: "px-4 pt-4 text-center"
        }, [_c('v-skeleton-loader', {
          attrs: {
            "type": "image",
            "height": "170px",
            "boilerplate": "",
            "elevation": "4"
          }
        }), _c('p', {
          staticClass: "text-subtitle-1 mt-4"
        }, [_vm._v(" " + _vm._s(_vm.$t('NoAvailableImage')) + " ")])], 1) : _vm.partsImageDataURL[item._id] ? _c('v-img', {
          attrs: {
            "src": _vm.partsImageDataURL[item._id]
          }
        }) : _c('div', [_c('SpinnerBeelse', {
          attrs: {
            "color": "orange",
            "display-logo": false,
            "size": 92
          }
        }), _c('p', {
          staticClass: "mt-8"
        }, [_vm._v(" " + _vm._s(_vm.$t('LoadingImage')) + " ")])], 1)], 1)], 1)], 1), _c('td', [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Part',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                partUUID: "".concat(item._id)
              }
            },
            "event": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(((_item$homologation = item.homologation) === null || _item$homologation === void 0 ? void 0 : _item$homologation.lastStatusUpdate) || item.last_modified), "long", _vm.$userLocale)) + " ")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('v-btn', {
          attrs: {
            "color": "primary",
            "to": {
              name: _vm.to,
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                partUUID: "".concat(item._id)
              }
            },
            "title": _vm.$t('view')
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }